<template>
  <div>
    <md-field class="md-chips" :class="[chipsClasses]">
      <label>Pesquisar por NIF/Projeto/Nome/NIC</label>
      <md-chip
        v-for="(chip, key) in value"
        :key="key"
        :md-deletable="!mdStatic"
        :md-clickable="!mdStatic"
        :md-duplicated="duplicatedChip === chip"
        @keydown.enter="$emit('md-click', chip, key)"
        @click.native="$emit('md-click', chip, key)"
        @md-delete.stop="removeChip(chip)">
        <slot name="md-chip" :chip="chip" v-if="$scopedSlots['md-chip']">{{ chip }}</slot>
        <template v-else>{{ chip }}</template>
      </md-chip>

      <md-input
        ref="input"
        v-model.trim="inputValue"
        v-if="!mdStatic && modelRespectLimit"
        :type="mdInputType"
        :id="id"
        :placeholder="mdPlaceholder"
        @input="handleInput"
        @keydown.enter="pushEnter()"
        @keydown.8="handleBackRemove">
      </md-input>
    </md-field>
    <md-menu :md-active.sync="toggleCard" :mdCloseOnClick="true" md-direction="bottom-end" class="menu-autocomplete" md-size="auto">
      <md-menu-content>
        <md-menu-item v-for="(item, index) in clients" :key="index" :disabled="!item.is_valid" :class="{ 'md-disabled-dropdown': !item.is_valid }" :index="index" @click="setInsertChip(item, true)">{{ item.customer_name }}</md-menu-item>
        <div class="loading-overlay" style="padding: 20px" v-if="notfound">
          <p>Nenhum resultado encontrado.</p>
        </div>
        <div class="loading-overlay" style="padding: 20px" v-if="clientLoading">
          <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
        </div>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
import MdPropValidator from '@/utils/MdPropValidator'
import MdUuid from '@/utils/MdUuid'
import Projects from '@/api/services/Projects.js'
import Api from '@/api'

export default {
  props: {
    value: Array,
    id: {
      type: [String, Number],
      default: () => 'md-chips-' + MdUuid()
    },
    mdInputType: {
      type: [String, Number],
      ...MdPropValidator('md-input-type', ['email', 'number', 'password', 'search', 'tel', 'text', 'url'])
    },
    mdPlaceholder: [String, Number],
    mdStatic: Boolean,
    mdLimit: Number,
    mdCheckDuplicated: {
      type: Boolean,
      default: false
    },
    mdFormat: {
      type: Function
    }
  },
  data: () => ({
    inputValue: '',
    inputPast: '',
    duplicatedChip: null,
    toggleCard: false,
    clients: [],
    clientLoading: false,
    clientsTemp: [],
    notfound: false,
    verifyTime: new Date()
  }),
  computed: {
    chipsClasses () {
      return {
        'md-has-value': this.value && this.value.length
      }
    },
    modelRespectLimit () {
      return !this.mdLimit || this.value.length < this.mdLimit
    },
    formattedInputValue () {
      if (!this.mdFormat) {
        return this.inputValue
      }
      return this.mdFormat(this.inputValue)
    }
  },
  methods: {
    pushEnter () {
      this.verifyTime = new Date()
      // this.$emit('md-alert-input', this.inputValue)
      if (isNaN(this.inputValue)) {
        this.insertChip('this')
        this.$emit('md-alert-input', this.inputValue)
      } else {
        this.insertChip('this')
        this.$emit('md-enter-input', 'chip')
      }
    },
    setInsertChip (item, menu = false) {
      if (menu) {
        var verify = (new Date() - this.verifyTime) / 1000
        if (verify < 0.020) {
          this.value.pop()
        }
      }
      this.inputValue = item.customer_name
      this.clientsTemp.push(item)
      this.insertChip(item)
    },
    insertChip ({ target }) {
      let inputValue = this.formattedInputValue

      inputValue = inputValue.replace(';', '')

      if (!inputValue || !this.modelRespectLimit) {
        return
      }

      if (this.value.includes(inputValue)) {
        this.duplicatedChip = null
        // to trigger animate
        this.$nextTick(() => {
          this.duplicatedChip = inputValue
        })
        return
      }

      this.value.push(inputValue)
      this.$emit('input', this.value)
      this.$emit('md-insert', inputValue)
      this.inputValue = ''
      this.updateData()
    },
    updateData () {
      var data = []
      this.value.forEach(object => {
        var item
        item = object
        if (isNaN(item)) {
          var result
          result = this.clientsTemp.filter((el) => {
            return el.customer_name === object
          })
          if (result.length > 0) {
            item = result[0].nif
          }
        }
        data.push(item)
      })
      this.$emit('selected', data)
    },
    removeChip (chip) {
      const index = this.value.indexOf(chip)

      this.value.splice(index, 1)
      this.$emit('input', this.value)
      this.$emit('md-delete', chip, index)
      this.$nextTick(() => this.$refs.input.$el.focus())
      this.updateData()
    },
    handleBackRemove () {
      if (!this.inputValue) {
        this.removeChip(this.value[this.value.length - 1])
      }
    },
    handleInput () {
      if (this.mdCheckDuplicated) {
        this.checkDuplicated()
      } else {
        this.duplicatedChip = null
      }
    },
    checkDuplicated () {
      if (!this.value.includes(this.formattedInputValue)) {
        this.duplicatedChip = null
        return false
      }

      if (!this.mdCheckDuplicated) {
        return false
      }

      this.duplicatedChip = this.formattedInputValue
    }
  },
  watch: {
    value () {
      this.checkDuplicated()
    },
    'inputValue': function (item) {
      this.notfound = false
      this.$emit('md-input-value', item)
      if (item.includes(';')) {
        this.insertChip(item.replace(';', ''))
      } else if (isNaN(item)) {
        if (item.length > 2) {
          if (!item.includes(this.inputPast)) {
            this.clients = []
          }
          this.inputPast = item
          this.clientLoading = true
          this.toggleCard = true
          Api.cancel()
          Projects.getClient(item).then(data => {
            this.clients = data.data
            if (this.clients.length === 0) {
              this.clientLoading = false
              this.notfound = true
            } else if (this.clients.length > 0) {
              this.clientLoading = false
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
  /**
 * The default transition, used when the element is visible
 * since the beginning of the animation
 * ---
 * @access private
 * @type transition
 * @group transition
 */

 $md-transition-default-timing: cubic-bezier(.4, 0, .2, 1);
 $md-transition-default-duration: .4s;
 $md-transition-default: $md-transition-default-duration $md-transition-default-timing;

 /**
  * The enter transition, used when the element is not visible on the screen
  * since the beginning of the animation and become visible
  * ---
  * @access private
  * @type transition
  * @group transition
  */

 $md-transition-enter-timing: cubic-bezier(0, 0, .2, 1);
 $md-transition-enter-duration: .4s;
 $md-transition-enter: $md-transition-enter-duration $md-transition-enter-timing;

 /**
  * The leave transition, used when the element is visible on the screen
  * since the beginning of the animation and is removed
  * ---
  * @access private
  * @type transition
  * @group transition
  */

 $md-transition-leave-timing: cubic-bezier(.4, .0, 1, 1);
 $md-transition-leave-duration: .4s;
 $md-transition-leave: $md-transition-leave-duration $md-transition-leave-timing;

/**
* The stand transition, used when the element is going to accelerate,
* like movements from bottom to top
* ---
* @access private
* @type transition
* @group transition
*/

$md-transition-stand-timing: cubic-bezier(.25, .8, .25, 1);
$md-transition-stand-duration: .4s;
$md-transition-stand: $md-transition-stand-duration $md-transition-stand-timing;

/**
* The out transition, used when the element is going to deaccelerate,
* like movements from top to bottom
* ---
* @access private
* @type transition
* @group transition
*/

$md-transition-drop-timing: cubic-bezier(.55, 0, .55, .2);
$md-transition-drop-duration: .4s;
$md-transition-drop: $md-transition-drop-duration $md-transition-drop-timing;

  .md-chips.md-field {
    padding-top: 12px;
    flex-wrap: wrap;

    &.md-has-value {
      label {
        top: -6px;
      }
    }

    .md-chip {
      margin-bottom: 4px;

      &:last-of-type {
        margin-right: 8px;
      }
    }

    .md-input {
      min-width: 128px;
    }
  }
.menu-autocomplete {
  margin: 0px;
}
.md-menu-content {
  margin-top: -40px;
  max-width: 90%;
}
.md-disabled-dropdown {
  .md-disabled {
    color: #cccccc !important;
  }
}
</style>
