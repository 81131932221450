<template>
  <div id="projects">
    <md-card class="table-altice">
      <md-card-header>
        <div class="md-title">Pesquisa</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <!-- <md-field>
              <label for="first-name">Pesquisar por NIF/LT/Nome</label>
              <md-input autocomplete="given-name" v-model="form.search" @keyup.enter="getNifOrLT()" />
            </md-field> -->
            <!-- <md-chips class="md-primary" v-model="form.searchChip" md-placeholder="Pesquisar por NIF/LT/Nome">
              <label>Pesquisar por NIF/LT/Nome</label>
              <!- <div class="md-helper-text">Para multiplos é necessário</div> --
            </md-chips> -->
            <auto-complete-chip class="md-primary" v-model="form.searchChip" @selected="chipEvent($event)" @md-alert-input="alertName($event)" @md-enter-input="$event; getNifOrLT()" md-placeholder="Pesquisar por NIF/Projeto/Nome/NIC" @md-input-value='handleInputAutoComplete($event)'></auto-complete-chip>
          </div>

          <div class="md-layout-item md-small-size-100">
            <md-button class="md-raised md-primary" @click="getNifOrLT()">Pesquisar</md-button>
            <md-button class=" md-primary" @click="download()">Download</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <md-table
      v-if="view == 'table' && projects"
      class="table-altice"
      v-model="searched"
      @md-selected="selected = event"
      :md-sort.sync="sortColumn"
      :md-sort-order.sync="sortOrder"
      md-card>
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title" v-if="!multileCustomer"><span v-if="projects[0]">{{ projects[0].customerName }}</span></h1>
        </div>

        <md-field md-clearable class="md-toolbar-section-start">
          <md-input placeholder="Filtrar projeto..." v-model="search" @input="searchOnTable" />
        </md-field>
        <!-- <md-button class="md-icon-button md-dense md-raised md-primary" @click="view = 'gantt'">
          <md-icon>short_text</md-icon>
        </md-button> -->
      </md-table-toolbar>

      <md-table-empty-state
        md-label=""
        :md-description="`Nenhum projeto encontrado.`">
      </md-table-empty-state>

      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="{ 'md-row-background': item.selected }" @mouseover="onSelectTable(item)" @mouseleave="offSelectTable(item)">
        <md-table-cell md-label="PROJETO" md-sort-by="lt"><b>{{ item.lt }}</b></md-table-cell>
        <md-table-cell v-if="multileCustomer" md-label="Empresa" md-sort-by="customerName">{{ item.customerName }}</md-table-cell>
        <md-table-cell style="max-width: 250px;" md-label="DESCRIÇÃO" md-sort-by="description">{{ item.description }}</md-table-cell>
        <md-table-cell md-label="DATA INÍCIO" md-sort-by="start">{{ item.start | dateFormat('DD/MM/YYYY') }}</md-table-cell>
        <md-table-cell md-label="DATA OBJETIVO" md-sort-by="end">{{ item.end | dateFormat('DD/MM/YYYY') }}</md-table-cell>
        <md-table-cell md-label="ÚLTIMA ATUALIZAÇÃO" md-sort-by="last">{{ item.last | dateFormat('DD/MM/YYYY') }}</md-table-cell>
        <md-table-cell md-label="GESTOR PROJETO" md-sort-by="manager">{{ item.manager }}</md-table-cell>
        <md-table-cell md-label="" md-sort-by="link">
          <md-button class="md-raised md-secondary" @click="detail(item)">Detalhe <md-icon class="icon-menu">keyboard_arrow_right</md-icon></md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-content  v-if="view == 'table'" id="projects" class="md-elevation-4 md-alignment-center-center table-altice">
      <gantt v-if="projects.length" style="padding-left: 10px;" :data="searched"></gantt>
    </md-content>
  </div>
</template>

<script>
import Gantt from '@/components/Gantt.vue'
import Projects from '@/api/services/Projects.js'
import AutoCompleteChip from '@/components/AutoCompleteChip.vue'
import Swal from 'sweetalert2'
import { sortBy, orderBy } from 'lodash-es'
import * as XLSX from 'xlsx/xlsx'

// Table Material
const toLower = text => {
  return text.toString().toLowerCase()
}

export default {
  name: 'AlticeProjects',
  computed: {
    filteredItems () {
      return this.projects
    },
    searched: {
      get: function () {
        // i just used lodash; lazy, i know...
        if (this.sortColumn === 'start') {
          return orderBy(this.projects, (a) => new Date(a.start), [this.sortOrder])
        } else if (this.sortColumn === 'end') {
          return orderBy(this.projects, (a) => new Date(a.end), [this.sortOrder])
        } else if (this.sortColumn === 'last') {
          return orderBy(this.projects, (a) => new Date(a.last), [this.sortOrder])
        }
        return sortBy(this.projects, this.sortColumn)
      },
      set: (items) => {
        return items
      }
    }
  },
  components: {
    Gantt,
    AutoCompleteChip
  },
  watch: {
    sortColumn () {
      this.$nextTick(() => (this.sortOrder = 'desc'))
    }
  },
  data () {
    return {
      view: 'table',
      search: '',
      allProjects: [],
      selected: {},
      selectedGantt: '',
      projects: [],
      changeData: {},
      form: {
        search: '',
        searchChip: [],
        listSearch: []
      },
      inputValueHandle: '',
      multileCustomer: false,
      sortColumn: 'lt',
      sortOrder: 'asc',
      columns: [
        {
          label: 'Projetos',
          field: 'lt'
        },
        {
          label: 'Empresa',
          field: 'customerName'
        },
        {
          label: 'DESCRIÇÃO',
          field: 'description'
        },
        {
          label: 'DATA INÍCIO',
          field: 'start'
        },
        {
          label: 'DATA OBJETIVO',
          field: 'end'
        },
        {
          label: 'ÚLTIMA ATUALIZAÇÃO',
          field: 'last'
        },
        {
          label: 'GESTOR PROJETO',
          field: 'manager'
        },
        {
          label: 'Data do PDS',
          field: 'date_status_report'
        },
        {
          label: 'PDS',
          field: 'last_pds_description'
        }
      ]
    }
  },
  methods: {
    searchByDescriptionAndLT (items, term) {
      if (term) {
        return items.filter(item => {
          return toLower(item.description).includes(toLower(term)) || toLower(item.lt).includes(toLower(term))
        })
      } else {
        return this.allProjects
      }
    },
    download () {
      this.$loading(true)
      let createXLSLFormatObj = []
      let newXlsHeader = []
      let vm = this
      var projects = []
      vm.projects.forEach(item => {
        var element = {}
        element = item
        Projects.getLtsHistory(item.lt, true).then(response => {
          this.$loading(true)
          element.last_pds_description = response.data.results.length > 0 ? response.data.results[0].status_md.replace(/(<([^>]+)>)/gi, '').replace(/(&nbsp;)+/g, ' ') : ''
          element.date_status_report = response.data.results.length > 0 ? response.data.results[0].date_status_report : ''
        })
        this.$loading(true)
        projects.push(element)
      })
      this.$loading(true)
      setTimeout(() => {
        this.$loading(true)
      }, 1000)
      setTimeout(() => {
        this.$loading(true)
        if (vm.columns.length === 0) {
          return
        }
        if (projects.length === 0) {
          return
        }
        vm.columns.map(column => {
          newXlsHeader.push(column.label)
        })
        createXLSLFormatObj.push(newXlsHeader)
        projects.map(value => {
          let innerRowData = []
          vm.columns.map(val => {
            let fieldValue = value[val.field]
            if (val.field.split('.').length > 1) {
              fieldValue = this.getNestedValue(value, val.field)
            }
            if (val.dataFormat && typeof val.dataFormat === 'function') {
              innerRowData.push(val.dataFormat(fieldValue))
            } else {
              innerRowData.push(fieldValue)
            }
          })
          createXLSLFormatObj.push(innerRowData)
        })
        let fileName = 'projects.xlsx'
        let wsName = 'projects'
        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)
        XLSX.utils.book_append_sheet(wb, ws, wsName)
        XLSX.writeFile(wb, fileName)
        this.$loading(false)
      }, 4000)
    },
    getNestedValue (object, string) {
      string = string.replace(/\[(\w+)\]/g, '.$1')
      string = string.replace(/^\./, '')
      let a = string.split('.')
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i]
        if (k in object) {
          object = object[k]
        } else {
          return
        }
      }
      return object
    },
    handleInputAutoComplete (item) {
      this.inputValueHandle = item
    },
    alertName (item) {
      this.getNifOrLT()
      // console.log('enter')
      // console.log(item)
      // if (item !== 'chip') {
      // }
      // Swal.fire(
      //   'Atenção!',
      //   'É necessário selecionar um cliente para fazer a busca.',
      //   'error'
      // )
    },
    chipEvent (item) {
      this.form.listSearch = item
    },
    getPercent (start, end) {
      var t = ((new Date().getTime() - new Date(start).getTime()) / (1000 * 3600 * 24)) * 100
      var day = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 3600 * 24)
      var simple = t / day + (Math.random() * (20 - 5) + 10)
      var result = simple >= 100 ? 90 : simple < 0 ? 0 : simple
      return Math.floor(result)
    },
    ganttSelected (item) {
      this.onSelectTable(this.projects[item])
    },
    onSelectTable (item) {
      this.projects.forEach(item => {
        item.selected = false
      })
      item.selected = true
      this.changeData = item
    },
    offSelectTable (item) {
      item.selected = false
      this.changeData = {}
    },
    searchOnTable () {
      this.projects = this.searchByDescriptionAndLT(this.allProjects, this.search)
    },
    detail (item, search = false) {
      if (search) {
        this.$router.push({ path: `/altice/detail/${item}` })
      } else {
        this.$store.commit('setProject', item)
        this.$router.push({ path: `/altice/detail/${item.lt}` })
      }
    },
    getNifOrLT () {
      // console.log(this.form.search)
      this.searched = []
      this.projects = []
      this.$loading(true)
      this.form.search = this.form.search.replace(new RegExp(' ', 'g'), '')
      // if (isNaN(this.form.search)) {
      //   Swal.fire(
      //     'Atenção!',
      //     'Existem caractéres especiais na sua busca. Para avançar por favor remova os caracteres especiais. Obrigado',
      //     'error'
      //   )
      //   this.$loading(false)
      // } else

      // console.log(this.form.listSearch.length)
      if (this.form.listSearch.length === 0 && this.inputValueHandle !== '') {
        this.form.listSearch = [this.inputValueHandle]
        this.form.searchChip = [this.inputValueHandle]
        this.$on('eventName', '')
      } else if (this.form.search !== '' && this.inputValueHandle === '' && this.form.listSearch.length === 0) {
        Swal.fire(
          'Atenção!',
          'Não podemos fazer a pesquisa de um campo vazio.',
          'error'
        )
        this.$loading(false)
      }

      // Validar se existe LT
      // Validar se contain só um LT
      if (this.form.listSearch.length > 0 && this.form.listSearch.length < 2) {
        this.form.listSearch[0] = this.form.listSearch[0].replace(new RegExp(' ', 'g'), '')
        if (this.form.listSearch[0].length === 7 && isNaN(this.form.listSearch[0]) === false) {
          // is LT
          if (isNaN(this.form.listSearch[0])) {
            Swal.fire(
              'Atenção!',
              'Existem caractéres especiais na sua busca. Para avançar por favor remova os caracteres especiais. Obrigado',
              'error'
            )
            this.$loading(false)
          } else if (this.form.listSearch[0].length === 7) {
            Projects.getLts(this.form.listSearch[0], true).then((response) => {
              if (response.data.results.length === 0) {
                Swal.fire(
                  'Atenção!',
                  'Nenhum Resultado encontrado.',
                  'error'
                )
                this.$loading(false)
              } else {
                this.detail(this.form.listSearch[0], true)
              }
            }).catch(error => {
              if (error.response.data.detail === 'in_black_list') {
                Swal.fire(
                  'Atenção!',
                  'Cliente não faz parte do âmbito do Portal de Projetos',
                  'info'
                )
              } else {
                Swal.fire(
                  'Atenção!',
                  'Erro na pesquisa, tente novamente.',
                  'error'
                )
              }
              this.$loading(false)
            })
          }
        } else {
          this.searchNifs()
          // console.log('else in')
          this.$loading(false)
        }
      } else {
        // console.log('else')
        this.searchNifs()
        this.$loading(false)
      }
    },
    async searchNifs () {
      var string = ''
      var valid = true
      var i = 0
      // console.log(this.form)
      await this.form.listSearch.forEach(async item => {
        i++
        if (item.length === 7 && isNaN(item) === false) {
          valid = false
        }
        if (isNaN(item)) {
          item = await Projects.getClient(item).then(data => {
            return data.data
          })
          item.forEach(value => {
            if (string === '') {
              string = value.nif
            } else {
              string = string + ';' + value.nif
            }
          })
        } else if (string === '') {
          string = item
        } else {
          string = string + ';' + item
        }
        if (i === this.form.listSearch.length) {
          this.getProjectsNow(valid, string)
        }
      })
    },
    getProjectsNow (valid, string) {
      if (valid) {
        // normalize
        string = string.replace(new RegExp(' ', 'g'), '').replace(new RegExp('%20', 'g'), '')
        Projects.getProjects(string).then((response) => {
          if (response.data.locked !== '' && response.data.locked !== ';') {
            var trunc = response.data.locked.split(';')
            trunc = trunc.filter(function (el) {
              return el !== ''
            })
            var str = ''
            var i = 0
            trunc.forEach(object => {
              if (i > 10) {
                return
              } else if (i === 10) {
                str = str + object + '...'
                i++
                return
              } else {
                str = str + object + '<br>'
              }
              i++
            })
            if (trunc.length > 2) {
              Swal.fire(
                'Atenção!',
                '<div style="text-align: left;">Clientes não fazem parte do âmbito do Portal de Projetos: <br><br>' + str + '</div>',
                'info'
              )
            } else if (trunc.length > 0) {
              Swal.fire(
                'Atenção!',
                '<div style="text-align: left;">Cliente não faz parte do âmbito do Portal de Projetos: <br><br>' + str + '</div>',
                'info'
              )
            } else {
              Swal.fire(
                'Atenção!',
                'Nenhum Resultado encontrado.',
                'error'
              )
            }
          } else {
            if (response.data.results.length === 0) {
              Swal.fire(
                'Atenção!',
                'Nenhum Resultado encontrado.',
                'error'
              )
            }
          }
          response.data.results.forEach(object => {
            if (new Date(object.date_award) > new Date(object.planned_date)) {
            } else {
              Projects.getLts(object.lt, true).then((responseInfo) => {
                this.projects.push({
                  'lt': object.lt,
                  'customerName': object.customer_name,
                  'description': object.opportunity_description,
                  'projectInfo': responseInfo.data.results[0],
                  'start': new Date(object.date_award),
                  'end': new Date(object.planned_date),
                  'last': new Date(object.date_last_update),
                  'manager': object.project_manager_name,
                  'percent': object.execution !== null ? object.execution : 0,
                  // 'percent': this.getPercent(new Date(object.date_award), new Date(object.planned_date)),
                  'selected': false
                })
              }).catch(() => {
              })
            }
          })
          this.$store.commit('setLastSearch', this.form.listSearch)
          this.$store.commit('setLastSearchProjects', response.data.results)
          this.allProjects = this.projects
          this.isMultiple()
          this.$loading(false)
        }).catch(error => {
          if (error.response.status === 400) {
            Swal.fire(
              'Atenção!',
              'Não temos resultado para sua pesquisa.',
              'info'
            )
          } else if (error.response.status === 401) {
            this.$router.push('/altice/login')
          }
          this.allProjects = this.projects
          this.isMultiple()
          this.$loading(false)
        })
      } else {
        Swal.fire(
          'Atenção!',
          'Ainda não é possível pesquisar um conjunto de NIFs com projetos juntos.',
          'error'
        )
        this.$loading(false)
      }
    },
    isMultiple () {
      var temp = ''
      var result = this.projects.filter((value) => {
        if (temp === '') {
          temp = value.customerName
        }
        return value.customerName === temp
      }).length
      this.multileCustomer = result !== this.projects.length
    }
  },
  created () {
    if (this.$store.state.lastSearchProjects && this.$store.state.lastSearchProjects.length > 0) {
      this.form.searchChip = this.$store.state.lastSearch
      this.$store.state.lastSearchProjects.forEach(object => {
        if (new Date(object.date_award) > new Date(object.planned_date)) {
        } else {
          Projects.getLts(object.lt, true).then((responseInfo) => {
            this.projects.push({
              'lt': object.lt,
              'customerName': object.customer_name,
              'description': object.opportunity_description,
              'projectInfo': responseInfo.data.results[0],
              'start': new Date(object.date_award),
              'end': new Date(object.planned_date),
              'last': new Date(object.date_last_update),
              'manager': object.project_manager_name,
              'percent': object.execution !== null ? object.execution : 0,
              // 'percent': this.getPercent(new Date(object.date_award), new Date(object.planned_date)),
              'selected': false
            })
          }).catch(() => {
          })
        }
      })
      this.allProjects = this.projects
      this.isMultiple()
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  box-shadow: none !important;
}
#projects{
  text-align: left;
  margin-bottom: 50px;
}
#chart_div{
  padding: 15px;
}
.card-altice {
  margin: auto auto;
  max-width: 1000px;
  // min-height: 45vh;
}
.table-altice {
  margin: auto auto;
  max-width: 95vw;
}
.middle-card {
  padding-top: 10vh;
  padding-left: 20px;
  padding-right: 20px;
}

.md-title{
  font-weight:700!important;
}

.md-raised{
  border-radius:5px;
  box-shadow: none!important;
  border: 2px solid black;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:40px;
  background-color: transparent !important;
  .icon-menu{
    font-size:10px!important;
    color:black!important;
    width:auto!important;
    min-width:auto!important;
  }
}

.md-raised:hover{
  border-radius:5px;
  box-shadow: none!important;
  border-color: #0084D6!important;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:40px;
  background-color:#0084D6!important;

  .md-button-content{
    color:white!important;
    .icon-menu{
      color:white!important;
    }
  }
}

.md-toolbar{
  padding-top:5px;
}

.toolbar-gantt {
  background-color: #ffffff !important;
}

.md-row-background{
  color: rgba(0, 0, 0, 1) !important;
}

.md-row-background:hover{
  font-weight: 500;
  transition: none;
}

.md-ripple{
  color: black !important;
}

.md-field:before{
  background-color:#0084D6!important;
  height:1px!important;
}

.md-field:after{
  background-color:#0084D6!important;
}

.md-table-empty-state{
  background-color:#f6f6f6!important;
  max-width:100%!important;
}

.md-empty-state{
  margin:0!important;
}

.md-empty-state-container[data-v-6cfeddb4]{
  padding:20px!important;
}

.md-empty-state-description{
  font-size:14px!important;
}

.md-table-head-label{
  color:black!important;
  font-weight:400!important;
}

.md-table-head-container:hover{
  .md-table-head-label{
    color:#0084D6!important;
    font-weight:700!important;
  }
}

.md-table-sortable-icon{
  right:8px;
  left:auto;
  svg{
    fill:#0084D6!important;
  }
}
</style>
